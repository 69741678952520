//CSS
import "../styling/navbar.css"

//Hooks
import { useNavigate } from "react-router-dom";

export default function NavbarFre({ changeToEnglish, changeToSpanish }) {

  const calendarLink = `https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2pFRk6NJH1lgD_mn-DvkTXwBniZKt9JZkkQW6t4yJWecyeqeMuofdxSmYGaQaTRFjVAbaKkpXO`

  const navigate = useNavigate()

  function openNav() {
    document.getElementById("myNav").style.width = "100%";
  }

  function closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }

  return (
    <div className="navbars">
      <div className="navbar">
        <div id="myNav" className="overlay">
          <button className="closebtn" onClick={() => closeNav()}>&times;</button>
          <div className="overlay-content"
            onClick={() => closeNav()}>
            <button className="overlay-content-bar"
              onClick={() => navigate("/home")}
            >Accueil</button>
            <button className="overlay-content-bar"
              onClick={() => navigate("/about")}
            >&Agrave; propos de nous</button>
            <button className="overlay-content-bar"
              onClick={() => navigate("/resources")}
            >Ressources</button>
            <button className="overlay-content-bar"
              onClick={() => navigate("/contact")}
            >Contacter</button>
            <a href={calendarLink} target="_blank" rel="noopener noreferrer" className="disabled-link">Calendrier</a>
            <button className="overlay-content-bar"
              onClick={() => navigate("/shop")}
            >Magazine</button>
          </div>
        </div>

        <span className="span-open-nav" onClick={() => openNav()}><i className="fa-solid fa-bars"></i></span>
        <span className="other-languages-left"
          onClick={() => changeToEnglish()}
        >English</span>
        <span className="other-languages-right"
          onClick={() => changeToSpanish()}
        >Espa&ntilde;ol</span>
      </div>

      <div className="navbar-big">

        <button onClick={() => navigate("/home")}>Accueil</button>
        <button onClick={() => navigate("/about")}>&Agrave; propos de nous</button>
        <button onClick={() => navigate("/resources")}>Ressources</button>
        <button onClick={() => navigate("/contact")}>Contacter</button>
        <a href={calendarLink} target="_blank" rel="noopener noreferrer" className="disabled-link">Calendrier</a>
        <button onClick={() => navigate("/shop")}>Magazine</button>
        <button href="#" className="split"
          onClick={() => changeToSpanish()}
        >Espa&ntilde;ol</button>
        <button href="#" className="split"
          onClick={() => changeToEnglish()}
        >English</button>

      </div>
    </div>



  )
}

