//CSS
import "./styling/contact.css"


import { useRef } from "react"
import emailjs from '@emailjs/browser';
// require('dotenv').config()

const { REACT_APP_YOUR_SERVICE_ID, REACT_APP_YOUR_TEMPLATE_ID, REACT_APP_YOUR_PUBLIC_KEY } = process.env;

export default function Contact({ language }) {

  // console.log("process.env", process.env)

  const form = useRef()

  const success = document.getElementById('success');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(


      `${REACT_APP_YOUR_SERVICE_ID}`,
      `${REACT_APP_YOUR_TEMPLATE_ID}`,
      form.current,
      `${REACT_APP_YOUR_PUBLIC_KEY}`
    )



      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    //Delete form after
    e.target.reset()
    success.style.display = 'block';
  };

  return (
    <div className="contact-component">
      <div className="contact">
        <h2 className="contact-title">
          {language === 'English' && (
            "Would you like to improve your Spanish or have you got any questions? Contact us!"
          )}
          {language === 'Spanish' && (
            "¿Quieres mejorar tu español o tienes preguntas? ¡Escríbenos!"
          )}
          {language === 'French' && (
            "Voulez-vous améliorer votre espagnol ou avez-vous de questions? Ecrivez nous!"
          )}
        </h2>
        <form ref={form}
          onSubmit={sendEmail}
          className="contact-form">

          {language === 'English' && (
            <input className="contact-input" type="text"
              placeholder="Name *"
              name="user_name" required />
          )}
          {language === 'Spanish' && (
            <input className="contact-input" type="text"
              placeholder="Nombre *"
              name="user_name" required />
          )}
          {language === 'French' && (
            <input className="contact-input" type="text"
              placeholder="Nom *"
              name="user_name" required />
          )}


          <input className="contact-input" type="email"
            placeholder=
            "Email *"
            name="user_email" required />

          {language === 'English' && (
            <input className="contact-input" type="text"
              placeholder="Subject *"
              name="subject" required />
          )}
          {language === 'Spanish' && (
            <input className="contact-input" type="text"
              placeholder="Asunto *"
              name="subject" required />
          )}
          {language === 'French' && (
            <input className="contact-input" type="text"
              placeholder="Sujet *"
              name="subject" required />
          )}

          {language === 'English' && (
            <textarea name="message"
              className="contact-text-area"
              cols="30" rows="10"
              placeholder="Message"
            ></textarea>
          )}
          {language === 'Spanish' && (
            <textarea name="message"
              className="contact-text-area"
              cols="30" rows="10"
              placeholder="Mensaje"
            ></textarea>
          )}
          {language === 'French' && (
            <textarea name="message"
              className="contact-text-area"
              cols="30" rows="10"
              placeholder="Message"
            ></textarea>
          )}

          <button type="submit"
            className="contact-submit-button"
          >
            Send Message
          </button>

          <div className="contact-submit-message">
            <div className="submit-message-success" id="success">Your message was sent!</div>
          </div>


        </form>
      </div>
    </div>
  )
}
