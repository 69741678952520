//Images
import imdb from '../../images/logos-other/imdb.png'
import netflix from '../../images/logos-other/netflix.png'
import youtube from '../../images/logos-other/youtube.png'
import filmin from '../../images/logos-other/filmin.png'
import rtve from '../../images/logos-other/rtve.png'

export default function TVSeries
  ({language}) {
  return (
    <div className="resource-component">
      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.imdb.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={imdb} alt="IMDb" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
            "IMDb (an acronym for Internet Movie Database) is an online database of information related to films, television programs, home videos, video games, and streaming content online – including cast, production crew and personal biographies, plot summaries, trivia, ratings, and fan and critical reviews."
          )}
          {language === 'Spanish' && (
            "IMDb (un acrónimo de Internet Movie Database) es una base de datos en línea de información relacionada con películas, programas de televisión, videos caseros, videojuegos y transmisión de contenido en línea, incluido el elenco, el equipo de producción y biografías personales, resúmenes de tramas, curiosidades, calificaciones y Fan y críticas críticas."
          )}
          {language === 'French' && (
            "IMDb (un acronyme pour Internet Movie Database) est une base de données en ligne d'informations relatives aux films, aux programmes de télévision, aux vidéos personnelles, aux jeux vidéo et au contenu en streaming en ligne - y compris les acteurs, l'équipe de production et les biographies personnelles, les résumés de l'intrigue, les anecdotes, les notes et critiques de fans et critiques."
          )}
        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.netflix.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={netflix} alt="IMDb" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
            "Netflix, Inc. is an over-the-top content platform and production company whose primary business is a subscription-based streaming service offering online streaming from a library of films and television series, including those produced in-house."
          )}
          {language === 'Spanish' && (
            "Netflix, Inc. es una plataforma de contenido y una productora de primer nivel cuyo negocio principal es un servicio de transmisión por suscripción que ofrece transmisión en línea de una biblioteca de películas y series de televisión, incluidas las producidas internamente."
          )}
          {language === 'French' && (
            "Netflix, Inc. est une plate-forme de contenu et une société de production over-the-top dont l'activité principale est un service de streaming par abonnement offrant une diffusion en ligne à partir d'une bibliothèque de films et de séries télévisées, y compris celles produites en interne."
          )}
          
        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.youtube.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={youtube} alt="IMDb" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
            "The famous online video-sharing platform offers film and series rentals and purchases."
          )}
          {language === 'Spanish' && (
            "La famosa plataforma de intercambio de videos en línea ofrece alquileres y compras de películas y series."
          )}
          {language === 'French' && (
            "La célèbre plateforme de partage de vidéos en ligne propose des locations et des achats de films et de séries."
          )}
          
        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.filmin.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={filmin} alt="IMDb" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
            "          Filmin is an online cinema platform created in Spain. It offers audiovisual content, auteur cinema, independent, commercial cinema in the form of video on demand in streaming and legally under subscription. Most of the films and series in the catalog are offered in two versions, the one dubbed into Spanish, V.E. (Spanish version), and the original subtitled in Spanish, V.O.S.E. (original version subtitled in Spanish)."
          )}
          {language === 'Spanish' && (
            "Filmin es una plataforma de cine online creada en España. Ofrece contenidos audiovisuales, cine de autor, cine independiente, comercial en forma de vídeo bajo demanda en streaming y legalmente bajo suscripción. La mayoría de las películas y series del catálogo se ofrecen en dos versiones, la doblada al español, V.E. (versión en español), y el original subtitulado en español, V.O.S.E. (versión original subtitulada en español)."
          )}
          {language === 'French' && (
            "Filmin est une plateforme de cinéma en ligne créée en Espagne. Elle propose des contenus audiovisuels, cinéma d'auteur, cinéma indépendant, commercial sous forme de vidéo à la demande en streaming et légalement sous abonnement. La plupart des films et séries du catalogue sont proposés en deux versions, celle doublée en espagnol, V.E. (version espagnole), et l'original sous-titré en espagnol, V.O.S.E. (version originale sous-titrée en espagnol)."
          )}


        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.rtve.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={rtve} alt="IMDb" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
            "RTVE is a state-owned public corporation and the largest audiovisual group in Spain broadcasting in the Spanish language."
          )}
          {language === 'Spanish' && (
            "RTVE es una corporación pública estatal y el mayor grupo audiovisual de España que emite en lengua española."
          )}
          {language === 'French' && (
            "RTVE est une société publique appartenant à l'État et le plus grand groupe audiovisuel d'Espagne diffusant en langue espagnole."
          )}

        </div>
      </div>

      <div className="resources-resource-title">
      {language === 'English' && (
            "Spain"
          )}
          {language === 'Spanish' && (
            "España"
          )}
          {language === 'French' && (
            "Espagne"
          )}
      </div>
      <div className="resource-paragraph">
        <ul>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0302447/" rel="noreferrer" target="_blank">Cuéntame cómo pasó (2001 - )</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0922037/" rel="noreferrer" target="_blank">La que se avecina (2007 - )</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt12446242/?ref_=fn_al_tt_1" rel="noreferrer" target="_blank">Alba (2021 - )</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt6468322/" rel="noreferrer" target="_blank">La casa de papel (2017 - 2021)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt4136774/" rel="noreferrer" target="_blank">El ministerio del tiempo (2015 - 2020)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt5674718/" rel="noreferrer" target="_blank">Las chicas del cable (2017 - 2020)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt4524056/" rel="noreferrer" target="_blank">Vis a vis (2015 - 2019)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt6762348/" rel="noreferrer" target="_blank">Velvet collection (2017 - 2019)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt5866074/" rel="noreferrer" target="_blank">Paquita Salas (2016 - 2019)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1864750/" rel="noreferrer" target="_blank">El tiempo entre costuras (2013 - 2014)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt2011533/" rel="noreferrer" target="_blank">Isabel (2011 - 2014)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt2006421/" rel="noreferrer" target="_blank">Gran hotel (2011 - 2013)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1381020/" rel="noreferrer" target="_blank">Águila roja (2009 - 2016)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt2955402/" rel="noreferrer" target="_blank">Velvet (2013 - 2016)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0381733/" rel="noreferrer" target="_blank">Aquí no hay quién viva (2003 - 2006)</a></li>
        </ul>
      </div>
      <div className="resources-resource-title">
      {language === 'English' && (
            "Mexico"
          )}
          {language === 'Spanish' && (
            "México"
          )}
          {language === 'French' && (
            "Mexique"
          )}
      </div>
      <div className="resource-paragraph">
        <ul>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt8714904/?ref_=nv_sr_srsg_0" rel="noreferrer" target="_blank">Narcos: México (2018 - 2021)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt8045690/?ref_=nv_sr_srsg_0" rel="noreferrer" target="_blank">Luis Miguel: La serie (2018 - 2021)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt8387348/?ref_=fn_al_tt_1" rel="noreferrer" target="_blank">La casa de las flores (2018 - 2020)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt4680240/" rel="noreferrer" target="_blank">Club de cuervos (2015 - 2019)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt6692188/?ref_=nv_sr_srsg_0" rel="noreferrer" target="_blank">El chapo (2017 - 2018)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt6495756/?ref_=nv_sr_srsg_0" rel="noreferrer" target="_blank">Ingobernable (2017 - 2018)</a></li>
        </ul>
      </div>
      <div className="resources-resource-title">
      {language === 'English' && (
            "Argentina"
          )}
          {language === 'Spanish' && (
            "Argentina"
          )}
          {language === 'French' && (
            "Argentine"
          )}
      </div>
      <div className="resource-paragraph">
        <ul>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt5820976/?ref_=nv_sr_srsg_0" rel="noreferrer" target="_blank">El marginal (2016)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt5563198/?ref_=fn_al_tt_1" rel="noreferrer" target="_blank">Un año sin nosotros (2015)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1699173/?ref_=fn_al_tt_1" rel="noreferrer" target="_blank">Todos contra Juan (2008 - 2009)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0459724/?ref_=fn_al_tt_1" rel="noreferrer" target="_blank">Vientos de agua (2006)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0316613/?ref_=nv_sr_srsg_0" rel="noreferrer" target="_blank">Los simuladores (2002 - 2003)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0289649/?ref_=nv_sr_srsg_0" rel="noreferrer" target="_blank">Okupas (2000)</a></li>
        </ul>
      </div>
    </div>
  )
}