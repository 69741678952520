//CSS
import "./resource-styling.css"

//Images
import rtve from '../../images/logos-other/rtve.png'
import rtveplay from '../../images/logos-other/rtve-play.png'
import atresplayer from '../../images/logos-other/atresplayer.jpg'
import mediaset from '../../images/logos-other/mediaset.png'
import aztecaamerica from '../../images/logos-other/aztecaamerica.png'
import univision from '../../images/logos-other/univision.jpg'
import telemundo from '../../images/logos-other/telemundo.png'
import televisa from '../../images/logos-other/televisa.png'
import aztecauno from '../../images/logos-other/aztecauno.png'
import telefe from '../../images/logos-other/telefe.png'
import canal13 from '../../images/logos-other/canal13.png'
import elnueve from '../../images/logos-other/elnueve.png'

export default function Television({ language }) {


  return (


    <div className="resource-component">
      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "Spain"
        )}
        {language === 'Spanish' && (
          "España"
        )}
        {language === 'French' && (
          "Espagne"
        )}
      </div>

      <div className="resource-images">
        <div className="resource-image">
          <a href="https://www.rtve.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={rtve} alt="RTVE" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.rtve.es/play/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={rtveplay} alt="RTVE Play" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.atresplayer.com//play/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={atresplayer} alt="atresplayer" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.mediaset.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={mediaset} alt="mediaset" /></a>
        </div>
      </div>


      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "U.S.A."
        )}
        {language === 'Spanish' && (
          "EE.UU."
        )}
        {language === 'French' && (
          "Etats-Unis"
        )}
      </div>

      <div className="resource-images">
        <div className="resource-image">
          <a href="https://www.aztecaamerica.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={aztecaamerica} alt="aztecaamerica" /></a>
        </div>

        <div className="resource-image">
          <a href="https://www.univision.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={univision} alt="univision" /></a>
        </div>

        <div className="resource-image">
          <a href="https://www.telemundo.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={telemundo} alt="telemundo" /></a>
        </div>
      </div>

      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "Mexico"
        )}
        {language === 'Spanish' && (
          "México"
        )}
        {language === 'French' && (
          "Mexique"
        )}
      </div>

      <div className="resource-images">
          <div className="resource-image">
            <a href="https://www.televisa.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={televisa} alt="televisa" /></a>
          </div>
          <div className="resource-image">
            <a href="https://www.tvazteca.com/aztecauno/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={aztecauno} alt="aztecauno" /></a>
          </div>
      </div>

      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "Argentina"
        )}
        {language === 'Spanish' && (
          "Argentina"
        )}
        {language === 'French' && (
          "Argentine"
        )}
      </div>

      <div className="resource-images">
          <div className="resource-image">
            <a href="https://www.telefe.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={telefe} alt="telefe" /></a>
          </div>
          <div className="resource-image">
            <a href="https://www.eltrecetv.com.ar/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={canal13} alt="canal13" /></a>
          </div>
          <div className="resource-image">
            <a href="https://www.elnueve.com.ar/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={elnueve} alt="elnueve" /></a>
          </div>
      </div>

    </div>



  )
}