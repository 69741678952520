//CSS
import "./resource-styling.css"

//Hooks
import { useNavigate } from "react-router-dom";

//Images
import book from '../../images/icons/icon-book.png'
import movie from '../../images/icons/icon-movie.png'
import tvseries from '../../images/icons/icon-series.png'
import television from '../../images/icons/icon-television.png'
import newspaper from '../../images/icons/icon-newspaper.png'
import radio from '../../images/icons/icon-radio.png'
import podcast from '../../images/icons/icon-podcasts.png'
import other from '../../images/icons/icon-www.png'

export default function ResourcesBar () {

const navigate = useNavigate()

  return (
    <div className="resources-bar">

<button className="resource-bar-button" onClick={() => navigate("/resources/books")}>
        <img className='resource-bar-img' src={book} alt="book" />
        <div className="resource-title">
        </div>
      </button>

      <button className="resource-bar-button"
        onClick={() => navigate("/resources/movies")}>
        <img className='resource-bar-img' src={movie} alt="movie" />
        <div className="resource-title">
        </div>
      </button>

      <button className="resource-bar-button"
        onClick={() => navigate("/resources/tvseries")}>
        <img className='resource-bar-img' src={tvseries} alt="tvseries" />
        <div className="resource-title">
        </div>
      </button>

      <button className="resource-bar-button"
        onClick={() => navigate("/resources/television")}>
        <img className='resource-bar-img' src={television} alt="television" />
        <div className="resource-title">
        </div>
      </button>

      <button className="resource-bar-button"
        onClick={() => navigate("/resources/newspapers")}>
        <img className='resource-bar-img' src={newspaper} alt="newspaper" />
        <div className="resource-title">
        </div>
      </button>

      <button className="resource-bar-button"
        onClick={() => navigate("/resources/radio")}>
        <img className='resource-bar-img' src={radio} alt="radio" />
        <div className="resource-title">
        </div>
      </button>

      <button className="resource-bar-button"
        onClick={() => navigate("/resources/podcasts")}>
        <img className='resource-bar-img' src={podcast} alt="podcast" />
        <div className="resource-title">
        </div>
      </button>

      <button className="resource-bar-button"
        onClick={() => navigate("/resources/other")}>
        <img className='resource-bar-img' src={other} alt="other" />
        <div className="resource-title">
        </div>
      </button>



    </div>
  )
}