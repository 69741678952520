//CSS
import "./styling/fullcalendar.css"


export default function FullCalendar({ language }) {
  return (
    <div>

      {language === 'English' && (
        <div className="message-box">
          My schedule is currently full and I am not able to accept new students. Please, email me if you wish to be informed about new openings. Also, I can put you in contact with Spanish teachers and other resources.
        </div>
      )}

      {language === 'Spanish' && (
        <div className="message-box">
        Mi horario está actualmente lleno y no puedo aceptar nuevos estudiantes. Por favor, envíame un correo electrónico si deseas estar informado sobre nuevas aperturas. Además, puedo ponerte en contacto con profesores de español y otros recursos.
        </div>
      )}

      {language === 'French' && (
        <div className="message-box">
        Je ne suis pas en mesure d'accepter de nouveaux étudiants. Écrivez-moi si vous souhaitez être informé des nouvelles ouvertures. De plus, je peux vous mettre en contact avec des professeurs d'espagnol et d'autres ressources.
        </div>
      )}

    </div>
  )
}