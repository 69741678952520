//CSS
import "../styling/navbar.css"

//Hooks
import { useNavigate } from "react-router-dom";

export default function NavbarEng({ changeToFrench, changeToSpanish }) {

  const calendarLink = `https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2pFRk6NJH1lgD_mn-DvkTXwBniZKt9JZkkQW6t4yJWecyeqeMuofdxSmYGaQaTRFjVAbaKkpXO`

  const navigate = useNavigate()

  function openNav() {
    document.getElementById("myNav").style.width = "100%";
  }

  function closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }

  return (
    <div className="navbars">
      <div className="navbar">
        <div id="myNav" className="overlay">
          <button className="closebtn" onClick={() => closeNav()}>&times;</button>
          <div className="overlay-content"
            onClick={() => closeNav()}>
            <button className="overlay-content-bar"
              onClick={() => navigate("/home")}
            >Home</button>
            <button className="overlay-content-bar"
              onClick={() => navigate("/about")}
            >About Us</button>
            <button className="overlay-content-bar"
              onClick={() => navigate("/resources")}
            >Resources</button>
            <button className="overlay-content-bar"
              onClick={() => navigate("/contact")}
            >Contact</button>
            <a href={calendarLink} target="_blank" rel="noopener noreferrer" className="disabled-link">Calendar</a>
            <button className="overlay-content-bar"
              onClick={() => navigate("/shop")}
            >Shop</button>
          </div>
        </div>

        <span className="span-open-nav" onClick={() => openNav()}><i className="fa-solid fa-bars"></i></span>
        <span className="other-languages-left"
          onClick={() => changeToSpanish()}
        >Espa&ntilde;ol</span>

        <span className="other-languages-right"
          onClick={() => changeToFrench()}
        >Fran&#231;ais</span>

      </div>

      <div className="navbar-big">


        <button onClick={() => navigate("/home")}>Home</button>
        <button onClick={() => navigate("/about")}>About Us</button>
        <button onClick={() => navigate("/resources")}>Resources</button>
        <button onClick={() => navigate("/contact")}>Contact</button>
        <a href={calendarLink} target="_blank" rel="noopener noreferrer" className="disabled-link">Calendar</a>
        <button onClick={() => navigate("/shop")}>Shop</button>
        <button className="split"
          onClick={() => changeToFrench()}
        >Fran&#231;ais</button>
        <button className="split"
          onClick={() => changeToSpanish()}>Espa&ntilde;ol</button>

      </div>
    </div>
  )
}

