//CSS
import "./resource-styling.css"

//Images
import imdb from '../../images/logos-other/imdb.png'
import netflix from '../../images/logos-other/netflix.png'
import youtube from '../../images/logos-other/youtube.png'
import filmin from '../../images/logos-other/filmin.png'
import rtve from '../../images/logos-other/rtve.png'

export default function Movies
  ({ language }) {
  return (
    <div className="resource-component">
      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
            <a href="https://www.imdb.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={imdb} alt="IMDb" /></a>
          </div>
        </div>
        <div className="--column-row">
          {language === 'English' && (
            "IMDb (an acronym for Internet Movie Database) is an online database of information related to films, television programs, home videos, video games, and streaming content online – including cast, production crew and personal biographies, plot summaries, trivia, ratings, and fan and critical reviews."
          )}
          {language === 'Spanish' && (
            "IMDb (un acrónimo de Internet Movie Database) es una base de datos en línea de información relacionada con películas, programas de televisión, videos caseros, videojuegos y transmisión de contenido en línea, incluido el elenco, el equipo de producción y biografías personales, resúmenes de tramas, curiosidades, calificaciones y Fan y críticas críticas."
          )}
          {language === 'French' && (
            "IMDb (un acronyme pour Internet Movie Database) est une base de données en ligne d'informations relatives aux films, aux programmes de télévision, aux vidéos personnelles, aux jeux vidéo et au contenu en streaming en ligne - y compris les acteurs, l'équipe de production et les biographies personnelles, les résumés de l'intrigue, les anecdotes, les notes et critiques de fans et critiques."
          )}
        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
            <a href="https://www.netflix.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={netflix} alt="netflix" /></a>
          </div>
        </div>
        <div className="--column-row">
          {language === 'English' && (
            "Netflix, Inc. is an over-the-top content platform and production company whose primary business is a subscription-based streaming service offering online streaming from a library of films and television series, including those produced in-house."
          )}
          {language === 'Spanish' && (
            "Netflix, Inc. es una plataforma de contenido y una productora de primer nivel cuyo negocio principal es un servicio de transmisión por suscripción que ofrece transmisión en línea de una biblioteca de películas y series de televisión, incluidas las producidas internamente."
          )}
          {language === 'French' && (
            "Netflix, Inc. est une plate-forme de contenu et une société de production over-the-top dont l'activité principale est un service de streaming par abonnement offrant une diffusion en ligne à partir d'une bibliothèque de films et de séries télévisées, y compris celles produites en interne."
          )}

        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
            <a href="https://www.youtube.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={youtube} alt="youtube" /></a>
          </div>
        </div>
        <div className="--column-row">
          {language === 'English' && (
            "The famous online video-sharing platform offers film and series rentals and purchases."
          )}
          {language === 'Spanish' && (
            "La famosa plataforma de intercambio de videos en línea ofrece alquileres y compras de películas y series."
          )}
          {language === 'French' && (
            "La célèbre plateforme de partage de vidéos en ligne propose des locations et des achats de films et de séries."
          )}

        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
            <a href="https://www.filmin.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={filmin} alt="filmin" /></a>
          </div>
        </div>
        <div className="--column-row">
          {language === 'English' && (
            "          Filmin is an online cinema platform created in Spain. It offers audiovisual content, auteur cinema, independent, commercial cinema in the form of video on demand in streaming and legally under subscription. Most of the films and series in the catalog are offered in two versions, the one dubbed into Spanish, V.E. (Spanish version), and the original subtitled in Spanish, V.O.S.E. (original version subtitled in Spanish)."
          )}
          {language === 'Spanish' && (
            "Filmin es una plataforma de cine online creada en España. Ofrece contenidos audiovisuales, cine de autor, cine independiente, comercial en forma de vídeo bajo demanda en streaming y legalmente bajo suscripción. La mayoría de las películas y series del catálogo se ofrecen en dos versiones, la doblada al español, V.E. (versión en español), y el original subtitulado en español, V.O.S.E. (versión original subtitulada en español)."
          )}
          {language === 'French' && (
            "Filmin est une plateforme de cinéma en ligne créée en Espagne. Elle propose des contenus audiovisuels, cinéma d'auteur, cinéma indépendant, commercial sous forme de vidéo à la demande en streaming et légalement sous abonnement. La plupart des films et séries du catalogue sont proposés en deux versions, celle doublée en espagnol, V.E. (version espagnole), et l'original sous-titré en espagnol, V.O.S.E. (version originale sous-titrée en espagnol)."
          )}


        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
            <a href="https://www.rtve.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={rtve} alt="rtve" /></a>
          </div>
        </div>
        <div className="--column-row">
          {language === 'English' && (
            "RTVE is a state-owned public corporation and the largest audiovisual group in Spain broadcasting in the Spanish language."
          )}
          {language === 'Spanish' && (
            "RTVE es una corporación pública estatal y el mayor grupo audiovisual de España que emite en lengua española."
          )}
          {language === 'French' && (
            "RTVE est une société publique appartenant à l'État et le plus grand groupe audiovisuel d'Espagne diffusant en langue espagnole."
          )}

        </div>
      </div>

      <div className="resources-resource-title">
        {language === 'English' && (
          "Awarded Movies"
        )}
        {language === 'Spanish' && (
          "Películas premiadas"
        )}
        {language === 'French' && (
          "Films primés"
        )}
      </div>
      <div className="resource-paragraph">
        <ul>
          <li>
            <a id="text-link-movie" href="https://www.premiosplatino.com" rel="noreferrer" target="_blank">
              {language === 'English' && (
                "Platino Award for Best Ibero-American Film"
              )}
              {language === 'Spanish' && (
                "Premio Platino a la Mejor Película Iberoamericana"
              )}
              {language === 'French' && (
                "Prix Platino du meilleur film ibéro-américain"
              )}
            </a>

          </li>
          <li>
            <a id="text-link-movie" href="https://www.amacc.org.mx/" rel="noreferrer" target="_blank">
              {language === 'English' && (
                "Ariel Award"
              )}
              {language === 'Spanish' && (
                "Premio Ariel"
              )}
              {language === 'French' && (
                "Prix Ariel"
              )}
            </a>

          </li>
          <li>
            <a id="text-link-movie" href="https://www.premiosgoya.com/" rel="noreferrer" target="_blank">
              {language === 'English' && (
                "Goya Awards"
              )}
              {language === 'Spanish' && (
                "Premios Goya"
              )}
              {language === 'French' && (
                "Prix Goya"
              )}
            </a>
          </li>
        </ul>
      </div>
      <div className="resources-resource-title">
        {language === 'English' && (
          "Spain"
        )}
        {language === 'Spanish' && (
          "España"
        )}
        {language === 'French' && (
          "Espagne"
        )}
      </div>
      <div className="resource-paragraph">
        <ul>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt12618926/" rel="noreferrer" target="_blank">Madres paralelas (2021)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt13066182/?ref_=fn_al_tt_1" rel="noreferrer" target="_blank">El buen patrón (2021)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt10434142/" rel="noreferrer" target="_blank">Las niñas  (2020)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt9616700/?ref_=nv_sr_srsg_0" rel="noreferrer" target="_blank">Adú (2020)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt8291806/" rel="noreferrer" target="_blank">Dolor y Gloria (2019)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt6793580/" rel="noreferrer" target="_blank">Campeones (2018)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt3416532/" rel="noreferrer" target="_blank">Un monstruo viene a verme (2016)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt4326444/" rel="noreferrer" target="_blank">Julieta (2016)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt3754940/" rel="noreferrer" target="_blank">Truman (2015)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt3253930/" rel="noreferrer" target="_blank">La isla mínima (2014)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1854513/" rel="noreferrer" target="_blank">Blancanieves (2012)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1189073/" rel="noreferrer" target="_blank">La piel que habito (2011)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1422032/" rel="noreferrer" target="_blank">También la lluvia (2010)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1242422/" rel="noreferrer" target="_blank">Celda 211 (2009)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0497465/" rel="noreferrer" target="_blank">Vicky Cristina Barcelona (2008)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1206285/" rel="noreferrer" target="_blank">Camino (2008)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0441909/" rel="noreferrer" target="_blank">Volver (2006)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0452971/" rel="noreferrer" target="_blank">Azul oscuro, casi negro (2006)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0430576/?ref_=fn_al_tt_1" rel="noreferrer" target="_blank">La vida secreta de las palabras (2005)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0369702/" rel="noreferrer" target="_blank">Mar Adentro (2004)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0319769/" rel="noreferrer" target="_blank">Los lunes al sol (2002)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0243794/" rel="noreferrer" target="_blank">El bola (2000)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0185125/" rel="noreferrer" target="_blank">Todo sobre mi madre (1999)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0190798/" rel="noreferrer" target="_blank">Solas (1999)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0158030/" rel="noreferrer" target="_blank">La niña de tus ojos (1998)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0146468/" rel="noreferrer" target="_blank">Barrio (1998)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0169025/" rel="noreferrer" target="_blank">Mensaka (1998)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0125659/" rel="noreferrer" target="_blank">Abre los ojos (1997)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0119626/" rel="noreferrer" target="_blank">Martín Hache (1997)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0118819/" rel="noreferrer" target="_blank">Carne trémula (1997)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0120090/" rel="noreferrer" target="_blank">Secretos del corazón (1997)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0114115/" rel="noreferrer" target="_blank">El perro del hortelano (1996)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0117883/" rel="noreferrer" target="_blank">Tesis (1996)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0113918/" rel="noreferrer" target="_blank">Nadie hablará de nosotras cuando hayamos muerto (1995)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0112922/" rel="noreferrer" target="_blank">El día de la bestia (1995)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0109699/" rel="noreferrer" target="_blank">Días contados (1994)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0108356/" rel="noreferrer" target="_blank">Todos los hombres sois iguales (1994)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0103791/?ref_=fn_al_tt_3" rel="noreferrer" target="_blank">Belle Époque (1992)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0099484/" rel="noreferrer" target="_blank">Las edades de Lulú (1990)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0101025/" rel="noreferrer" target="_blank">¡Ay, Carmela! (1990)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0094641/" rel="noreferrer" target="_blank">Amanece que no es poco (1989)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0095675/" rel="noreferrer" target="_blank">Mujeres al borde de un ataque de nervios (1988)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0092170/" rel="noreferrer" target="_blank">La vida alegre (1987)</a></li>
        </ul>
      </div>
      <div className="resources-resource-title">
        {language === 'English' && (
          "Mexico"
        )}
        {language === 'Spanish' && (
          "México"
        )}
        {language === 'French' && (
          "Mexique"
        )}
      </div>
      <div className="resource-paragraph">
        <ul>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt11394282/" rel="noreferrer" target="_blank">Sin señas particulares (2020)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt4323594/" rel="noreferrer" target="_blank">Ya no estoy aquí (2019)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt4823434/" rel="noreferrer" target="_blank">Vuelven (2017)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt6397762/" rel="noreferrer" target="_blank">Las hijas de abril (2017)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt3278224/" rel="noreferrer" target="_blank">Sueño en otro idioma (2017)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt3800560/" rel="noreferrer" target="_blank">Tempestad (2016)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt2197926/" rel="noreferrer" target="_blank">La 4ª compañía (2016)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt4636042/" rel="noreferrer" target="_blank">Las elegidas (2015)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt3499048/" rel="noreferrer" target="_blank">Güeros (2014)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt2852376/" rel="noreferrer" target="_blank">Heli (2013)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt2042583/" rel="noreferrer" target="_blank">La jaula de oro (2013)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt2011183/" rel="noreferrer" target="_blank">Pastorela (2011)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1692190/" rel="noreferrer" target="_blank">El infierno (2010)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1143148/" rel="noreferrer" target="_blank">Cinco días sin Nora (2008)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0481320/" rel="noreferrer" target="_blank">Párpados azules (2007)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0457430/" rel="noreferrer" target="_blank">El laberinto del fauno (2006)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0245574/" rel="noreferrer" target="_blank">Y tu mamá también (2001)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0245712/" rel="noreferrer" target="_blank">Amores perros (2000)</a></li>
        </ul>
      </div>
      <div className="resources-resource-title">
        {language === 'English' && (
          "Argentina"
        )}
        {language === 'Spanish' && (
          "Argentina"
        )}
        {language === 'French' && (
          "Argentine"
        )}
      </div>
      <div className="resource-paragraph">
        <ul>

          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt13528580/?ref_=fn_al_tt_1" rel="noreferrer" target="_blank">Las siamesas (2020)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt10899680/?ref_=fn_al_tt_1" rel="noreferrer" target="_blank">Los sonámbulos (2019)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt7204348/" rel="noreferrer" target="_blank">El ángel (2018)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt3409848/" rel="noreferrer" target="_blank">Zama (2017)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt4955012/" rel="noreferrer" target="_blank">La luz incidente (2015)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt3717390/" rel="noreferrer" target="_blank">Refugiado (2014)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt3011894/" rel="noreferrer" target="_blank">Relatos salvajes (2014)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1847746/" rel="noreferrer" target="_blank">Wakolda (2013)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1726888/" rel="noreferrer" target="_blank">Infancia clandestina (2011)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1705786/" rel="noreferrer" target="_blank">Un cuento chino (2011)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1529252/" rel="noreferrer" target="_blank">El hombre de al lado (2009)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt1305806/" rel="noreferrer" target="_blank">El secreto de sus ojos (2009)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0318462/" rel="noreferrer" target="_blank">Diarios de motocicleta (2004)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0329330/" rel="noreferrer" target="_blank">Lugares comunes (2002)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0292542/" rel="noreferrer" target="_blank">El hijo de la novia (2001)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0247586/" rel="noreferrer" target="_blank">Nueve reinas (2000)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0104774/" rel="noreferrer" target="_blank">Un lugar en el mundo (1992)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0089276/" rel="noreferrer" target="_blank">La historia oficial (1985)</a></li>
          <li><a id="text-link-movie" href="https://www.imdb.com/title/tt0083203/" rel="noreferrer" target="_blank">Tiempo de revancha (1981)</a></li>
        </ul>
      </div>
    </div>
  )
}