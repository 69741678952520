//Styling
import './styling/calendar.css';

//Images
import calendar from '../images/images-other/calendar.jpg'

//External URLs
const newStudentsCalendar = `https://docs.google.com/spreadsheets/d/1r3SN8arOkWW7NOeg-1PRExKrIG_CNoMg8wQgf50XthE/edit#gid=1675564905`

// const currentStudentsCalendar = `https://docs.google.com/spreadsheets/d/1OMK0vCegPDUCzRJScQgphs3wCBuK_yyHGJYjQHYyKVQ/edit#gid=1675564905`

const currentStudentsCalendar = `https://calendar.google.com/calendar/u/0/selfsched?sstoken=UUZfc0dKN2lNdUkwfGRlZmF1bHR8N2ExNmNkY2E1MWQ0OWUwMTlmZWZkOGExZmNkMjg2ZGI`


export default function Calendar({ language }) {

  return (
    <div className="calendar">
      <div className='calendar-img'>
        <img className='calendar-logo-img' src={calendar} alt="Calendar" />
      </div>
      <div className='students'>
        <button>
          <a id="students-button" href={currentStudentsCalendar} target="_blank" rel="noopener noreferrer">

            {language === 'English' && (
              "Current Students"
            )}

            {language === 'Spanish' && (
              "Estudiantes actuales"
            )}

            {language === 'French' && (
              "Étudiants actuels"
            )}

          </a>
        </button>
        <button>
          <a id="students-button" href={newStudentsCalendar} target="_blank" rel="noopener noreferrer">

            {language === 'English' && (
              "New Students"
            )}

            {language === 'Spanish' && (
              "Nuevos estudiantes"
            )}

            {language === 'French' && (
              "Nouveaux étudiants"
            )}

          </a>
        </button>
      </div>
      <div className='calendar-img-acknowledgment'>
        Image by&nbsp; <a id="calendar-acknow-link" href="https://pixabay.com/es/users/basti93-169785/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=273857" rel="noreferrer" target="_blank"> Bastian Wiedenhaupt</a>  &nbsp;in Pixabay
      </div>
    </div>
  )
}

