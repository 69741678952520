//Images
import whitelogo from '../images/logos-casanova/white-logo.png'

//CSS
import '../App.css'

//Hooks
import { useNavigate } from "react-router-dom";


export default function Main({language, changeToEnglish, changeToFrench, changeToSpanish}) {

  const navigate = useNavigate()
  return (
      <div className="main">
        <div className='mainpage-logo'>
          <img className='mainpage-logo-img' src={whitelogo} alt="Casanova Spanish" />
          {/* <div className='mainpage-logo-img'>Logo</div> */}
        </div>
        <div className='languages'>
          <div className='language' 
          onMouseEnter={() => changeToEnglish()} 
          onClick={() => navigate('/home')}>English</div>
          <div className='language' 
          onMouseEnter={() => changeToFrench()}
          onClick={() => navigate('/home')}>Fran&#231;ais</div>
          <div className='language' 
          onMouseEnter={() => changeToSpanish()} 
          onClick={() => navigate('/home')}>Espa&ntilde;ol</div>
        </div>
        <div className='social'>
          <a id="main-social-link" href="https://www.linkedin.com/in/manuel-casanova/" rel="noreferrer" target="_blank"><i className="fa-brands fa-linkedin-in fa-social"></i></a>
          <a id="main-social-link" className="fa-social" href="mailto:info@casanovaspanish.com?subject=Spanish lessons"><i className="fa-solid fa-envelope" /></a>
        </div>
        <div className='background-img-acknowledgment'>
          Image by&nbsp; <a id="main-acknow-link" href="https://pixabay.com/es/users/chezbeate-1519166/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1077084" rel="noreferrer" target="_blank" className="link"> chezbeate</a>  &nbsp;in Pixabay
        </div>
      </div>
  )

}