export default function ShopSpa () {
  return (
    <div className="shop">
      
      <a href="mailto:info@casanovaspanish.com?subject=Free trial lesson"><button className="shop-button">Lecci&oacute;n gratuita de prueba</button></a>
      <a href="mailto:info@casanovaspanish.com?subject=Online lessons"><button className="shop-button">Clases en l&iacute;nea</button></a>
      <div className="shop-lessons">
      <a href="mailto:info@casanovaspanish.com?subject=60 minute lessons"><button className="shop-button">60 min - 36.45 CAD</button></a>
      <a href="mailto:info@casanovaspanish.com?subject=90 minute lessons"><button className="shop-button">90 min - 46.80 CAD</button></a>
      </div>

    </div>
  )
}