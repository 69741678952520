//Images
import redlogo from '../images/logos-casanova/logo.png'

//CSS
import "./styling/header.css"

//Hooks
import { useNavigate } from "react-router-dom"

export default function Header() {

  const navigate = useNavigate()

  return (
    <div className="header">
      <div className='header-logo'>
        <img className='header-logo-img' src={redlogo} alt="Casanova Spanish" onClick={() => navigate("/")}/>
      </div>
    </div>
  )
}