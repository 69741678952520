import './App.css';

//Fonts
import "./fonts/georgia-italic.ttf";

//Components
import Header from './components/Header';
import Navbar from './components/Navbar';
import Main from './components/Main';
import Home from './components/Home';
import About from './components/About';
import Resources from './components/Resources';
import Contact from './components/Contact';
import Calendar from './components/Calendar';
import Shop from './components/Shop';
import Books from './components/resources/Books';
import Movies from './components/resources/Movies';
import TVSeries from './components/resources/TVSeries';
import Television from './components/resources/Television';
import Newspapers from './components/resources/Newspapers';
import Radio from './components/resources/Radio';
import Podcasts from './components/resources/Podcasts';
import Other from './components/resources/Other';
import ResourcesBar from './components/resources/ResourcesBar';
import Certificates from './components/Certificates';

//Hooks
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";


function App() {

  const [language, setLanguage] = useState("English")

  const changeToSpanish = () => {
    setLanguage("Spanish");
  }

  const changeToEnglish = () => {
    setLanguage("English");
  }

  const changeToFrench = () => {
    setLanguage("French");
  }

  return (

    <Router>
      <div className='App'>
        <Routes>
          <Route>
            <Route exact path="/" element={<Main
              language={language}
              changeToEnglish={changeToEnglish}
              changeToFrench={changeToFrench}
              changeToSpanish={changeToSpanish}
            />}></Route>

            <Route exact path="/home" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <Home language={language} />
              </>
            }>
            </Route>

            <Route exact path="/about" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <About language={language} />
              </>
            }></Route>

            <Route exact path="/resources" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <Resources language={language} />
              </>
            }></Route>

            <Route exact path="/resources/books" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <div className='resources-bar-and-component'>
                  <ResourcesBar />
                  <Books language={language} />
                </div>

              </>
            }></Route>

            <Route exact path="/resources/movies" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <div className='resources-bar-and-component'>
                  <ResourcesBar />
                  <Movies language={language} />
                </div>
              </>
            }></Route>

            <Route exact path="/resources/tvseries" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <div className='resources-bar-and-component'>
                  <ResourcesBar />
                  <TVSeries language={language} />
                </div>
              </>
            }></Route>

            <Route exact path="/resources/television" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <div className='resources-bar-and-component --not-centered'>
                  <ResourcesBar />
                  <Television language={language} />
                </div>
              </>
            }></Route>

            <Route exact path="/resources/newspapers" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <div className='resources-bar-and-component --not-centered'>
                  <ResourcesBar />
                  <Newspapers language={language} />
                </div>
              </>
            }></Route>

            <Route exact path="/resources/radio" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <div className='resources-bar-and-component --not-centered'>
                  <ResourcesBar />
                  <Radio language={language} />
                </div>
              </>
            }></Route>

            <Route exact path="/resources/podcasts" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <div className='resources-bar-and-component --not-centered'>
                  <ResourcesBar />
                  <Podcasts language={language} />
                </div>
              </>
            }></Route>

            <Route exact path="/resources/other" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <div className='resources-bar-and-component'>
                  <ResourcesBar />
                  <Other language={language} />
                </div>
              </>
            }></Route>

            <Route exact path="/contact" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <Contact language={language} />
              </>
            }></Route>

            <Route exact path="/calendar" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <Calendar
                  language={language}
                />
              </>
            }></Route>

            <Route exact path="/shop" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <Shop language={language} />
              </>
            }></Route>

            <Route exact path="/certificates" element={
              <>
                <Header />
                <Navbar
                  language={language}
                  changeToEnglish={changeToEnglish}
                  changeToFrench={changeToFrench}
                  changeToSpanish={changeToSpanish}
                />
                <Certificates language={language} />
              </>
            }></Route>


          </Route>
        </Routes>
      </div>
    </Router>



  );
}

export default App;
