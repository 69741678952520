//CSS
import "./resource-styling.css"

//Images
import elmundo from '../../images/logos-other/elmundo.png'
import elpais from '../../images/logos-other/elpais.png'
import lavanguardia from '../../images/logos-other/lavanguardia.png'
import abc from '../../images/logos-other/abc.png'
import marca from '../../images/logos-other/marca.png'
import soldemexico from '../../images/logos-other/soldemexico.png'
import ovaciones from '../../images/logos-other/ovaciones.png'
import laprensa from '../../images/logos-other/laprensa.png'
import diariodemexico from '../../images/logos-other/diariodemexico.png'
import eluniversal from '../../images/logos-other/eluniversal.png'
import clarin from '../../images/logos-other/clarin.png'
import lanacion from '../../images/logos-other/lanacion.png'
import diariopopular from '../../images/logos-other/diariopopular.png'



export default function Newspapers({ language }) {


  return (


    <div className="resource-component">
      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "Spain"
        )}
        {language === 'Spanish' && (
          "España"
        )}
        {language === 'French' && (
          "Espagne"
        )}
      </div>

      <div className="resource-images">
        <div className="resource-image">
          <a href="https://www.elmundo.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={elmundo} alt="elmundo" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.elpais.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={elpais} alt="elpais" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.lavanguardia.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={lavanguardia} alt="lavanguardia" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.abc.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={abc} alt="abc" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.marca.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={marca} alt="marca" /></a>
        </div>
      </div>

      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "Mexico"
        )}
        {language === 'Spanish' && (
          "México"
        )}
        {language === 'French' && (
          "Mexique"
        )}
      </div>

      <div className="resource-images">
        <div className="resource-image">
          <a href="https://www.elsoldemexico.com.mx/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={soldemexico} alt="soldemexico" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.ovaciones.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={ovaciones} alt="ovaciones" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.la-prensa.com.mx/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={laprensa} alt="laprensa" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.diariodemexico.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={diariodemexico} alt="diariodemexico" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.eluniversal.com.mx/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={eluniversal} alt="eluniversal" /></a>
        </div>
      </div>

      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "Argentina"
        )}
        {language === 'Spanish' && (
          "Argentina"
        )}
        {language === 'French' && (
          "Argentine"
        )}
      </div>

      <div className="resource-images">
        <div className="resource-image">
          <a href="https://www.clarin.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={clarin} alt="clarin" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.lanacion.com.ar/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={lanacion} alt="lanacion" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.diariopopular.com.ar/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={diariopopular} alt="diariopopular" /></a>
        </div>
      </div>

    </div>



  )
}