//Image
import manuel from "../../images/images-casanova/manuel.jpg"

export default function AboutSpa() {
  return (
    <div className="about">
      <img className='about-picture' src={manuel} alt="Manuel" />
      <div className="about-text">
        <p>
          Manuel Casanova es profesor de español como lengua extranjera. Imparte clases de español en l&iacute;nea desde Vancouver, B.C. (Canadá). Anteriormente, enseñaba español a solicitantes de asilo y beneficiarios de protección internacional en Madrid. Durante muchos años, se dedic&oacute; al mundo del turismo y ha vivido en Finlandia, República Dominicana, Haití, España y Canadá.
        </p>


        <p>
          Desde el año 2016, Manuel enseña español a extranjeros. Acumula más de 1.900 horas de formación certificada incluyendo un Máster en Lingüística Aplicada a la Enseñanza del Español como Lengua Extranjera, así como 180 horas de formación específica en enseñanza de idiomas en línea y diseño de materiales. También est&aacute; acreditado por el Instituto Cervantes como examinador DELE.
        </p>

        <p>
          Manuel habla inglés y francés. Le gusta aprender sobre distintas culturas, ver películas o documentales, leer y montar en bicicleta. Además, le apasiona el español.
        </p>

        <p>

          ¡Bienvenido y esperamos que disfrutes aprendiendo este idioma!
        </p>
      </div>
    </div>
  )
}