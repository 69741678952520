//Image
import manuel from "../../images/images-casanova/manuel.jpg"

export default function About() {
  return (
    <div className="about">
      <img className='about-picture' src={manuel} alt="Manuel" />
      <div className="about-text">
        <p>
          Manuel Casanova is a Teacher of Spanish to Speakers of Other Languages. He teaches private lessons in Vancouver, BC (Canada). Previously, Manuel taught Spanish  to asylum applicants and beneficiaries of international protection in Madrid, Spain. For many years he developed his career in the tourism industry and has lived in Finland, Dominican Republic, Haiti, Spain and Canada.
        </p>


        <p>
          Manuel teaches Spanish to foreigners since 2016. He has gathered over 1,900 hours of certified training including a Master of Science in Applied Linguistics / Teaching of Spanish as a Foreign Language. Among them, he has over 180 hours of specific training in online teaching and editing of learning materials. Manuel is also accredited by the Instituto Cervantes as a DELE examiner.
        </p>

        <p>
          Manuel is fluent in English and French. He enjoys learning about different cultures, watching movies or documentaries, reading, and road cycling. Furthermore, he loves Spanish.
        </p>

        <p>
          Welcome, we hope you enjoy learning this language!
        </p>
      </div>
    </div>
  )
}