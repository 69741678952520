//CSS
import "./styling/shop.css"

//Components
import ShopEng from "./english/ShopEng";
import ShopSpa from "./spanish/ShopSpa";
import ShopFre from "./french/ShopFre";


export default function Shop({ language }) {
  return (
    <div>

      {language === 'English' && (
        <ShopEng />
      )}

      {language === 'Spanish' && (
        <ShopSpa />
      )}

      {language === 'French' && (
        <ShopFre />
      )}

    </div>
  )
}