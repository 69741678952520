//Images
import profedeele from '../../images/logos-other/profedeele.png'
import duolingo from '../../images/logos-other/duolingo.png'
import babbel from '../../images/logos-other/babbel.png'
import mosalingua from '../../images/logos-other/mosalingua.jpg'
import busuu from '../../images/logos-other/busuu.png'
import rosetta from '../../images/logos-other/rosetta.jpg'
import memrise from '../../images/logos-other/memrise.png'

export default function Other
  ({language}) {
  return (
    <div className="resource-component">
      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.profedeele.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={profedeele} alt="profedeele" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
          "ProfeDeELE is a portal for students and teachers of Spanish as a foreign language with a wide variety of ideas, resources and practical activities, published freely, free of charge and that prioritize creativity, originality and quality."
        )}
        {language === 'Spanish' && (
          "Profedeele es un portal para estudiantes y maestros de español como lengua extranjera con una amplia variedad de ideas, recursos y actividades prácticas, publicados libremente, de forma gratuita y que priorizan la creatividad, la originalidad y la calidad."
        )}
        {language === 'French' && (
          "ProfeDeELE est un portail pour étudiants et professeurs d'espagnol langue étrangère avec une grande variété d'idées, de ressources et d'activités pratiques, publiées librement, gratuitement et qui privilégient la créativité, l'originalité et la qualité."
        )}
        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.duolingo.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={duolingo} alt="duolingo" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
          "        Learning with Duolingo is fun and addictive. Earn points for correct answers, race against the clock, and level up. "
        )}
        {language === 'Spanish' && (
          "Aprender con Duolingo es divertido y adictivo. Gana puntos por las respuestas correctas, corre contrarreloj y sube de nivel."
        )}
        {language === 'French' && (
          "Apprendre avec Duolingo est amusant et addictif. Gagnez des points pour les bonnes réponses, faites la course contre la montre et montez de niveau."
        )}

        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.babbel.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={babbel} alt="babbel" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
          "Bite-Sized Lessons: 10-15 minute lessons perfect for learning on the go or whenever there’s an opening in your schedule. Learn to have real-life conversations, and get your pronunciation on point with an integrated speech recognition technology."
        )}
        {language === 'Spanish' && (
          "Lecciones breves: lecciones de 10 a 15 minutos perfectas para aprender sobre la marcha o cuando haya una vacante en su agenda. Aprende a tener conversaciones de la vida real y mejora tu pronunciación con una tecnología de reconocimiento de voz integrada."
        )}
        {language === 'French' && (
          "Petites leçons : des leçons de 10 à 15 minutes parfaites pour apprendre en déplacement ou chaque fois qu'il y a une ouverture dans votre emploi du temps. Apprenez à avoir des conversations réelles et améliorez votre prononciation grâce à une technologie de reconnaissance vocale intégrée."
        )}
        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.mosalingua.com/es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={mosalingua} alt="mosalingua" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
          "MosaLingua is an effective and interactive language learning method. Based on the fundamental principles of cognitive science (Spaced Repetition System, Active recall, Pareto's Law ...), the MOSA Learning method allows you to optimize your learning and review what you have learned at the most opportune moment so as not to forget it."
        )}
        {language === 'Spanish' && (
          "MosaLingua es un método de aprendizaje de idiomas efectivo e interactivo. Basado en los principios fundamentales de la ciencia cognitiva (Sistema de Repetición Espaciada, Memoria Activa, Ley de Pareto…), el método MOSA Learning te permite optimizar tu aprendizaje y repasar lo aprendido en el momento más oportuno para no olvidarlo."
        )}
        {language === 'French' && (
          "MosaLingua est une méthode d'apprentissage des langues efficace et interactive. Basée sur les principes fondamentaux des sciences cognitives (Système de répétition espacée, Rappel actif, Loi de Pareto...), la méthode MOSA Learning vous permet d'optimiser votre apprentissage et de revoir ce que vous avez appris au moment le plus opportun pour ne pas l'oublier ."
        )}


        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.busuu.com/es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={busuu} alt="busuu" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
          "Complete language courses. Short lessons, perfect for everyday life, work and travel."
        )}
        {language === 'Spanish' && (
          "Cursos completos de idiomas. Lecciones cortas, perfectas para la vida cotidiana, el trabajo y los viajes."
        )}
        {language === 'French' && (
          "Cours de langue complets. Cours courts, parfaits pour la vie quotidienne, le travail et les voyages."
        )}
        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.rosettastone.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={rosetta} alt="rosetta" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
          "Rosetta Stone is dedicated to changing people's lives through the power of language education. The company's innovative digital solutions drive positive learning outcomes for the inspired learner at home or in schools and workplaces around the world"
        )}
        {language === 'Spanish' && (
          "Rosetta Stone se dedica a cambiar la vida de las personas a través del poder de la educación lingüística. Las soluciones digitales innovadoras de la compañía generan resultados de aprendizaje positivos para el alumno inspirado en el hogar o en las escuelas y lugares de trabajo de todo el mundo."
        )}
        {language === 'French' && (
          "Rosetta Stone se consacre à changer la vie des gens grâce au pouvoir de l'enseignement des langues. Les solutions numériques innovantes de l'entreprise génèrent des résultats d'apprentissage positifs pour l'apprenant inspiré à la maison ou dans les écoles et les lieux de travail du monde entier."
        )}
        </div>
      </div>

      <div className="--column-row">
        <div className="resource-images">
          <div className="resource-image">
          <a href="https://www.memrise.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={memrise} alt="memrise" /></a>
          </div>
        </div>
        <div className="--column-row">
        {language === 'English' && (
          "Memrise is a language platform that uses spaced repetition of flashcards to increase the rate of learning."
        )}
        {language === 'Spanish' && (
          "Memrise es una plataforma de idiomas que utiliza la repetición espaciada de tarjetas didácticas para aumentar el ratio de aprendizaje."
        )}
        {language === 'French' && (
          "Memrise est une plate-forme linguistique qui utilise la répétition espacée de flashcards pour augmenter le taux d'apprentissage."
        )}
        </div>
      </div>

      </div>
  )
}