//Image
import manuel from "../../images/images-casanova/manuel.jpg"

export default function AboutFre() {
  return (
    <div className="about">
      <img className='about-picture' src={manuel} alt="Manuel" />
      <div className="about-text">
        <p>
          Manuel Casanova est professeur d'espagnol comme langue étrangère. Il donne des cours d'espagnol en ligne de Vancouver, B.C. (Canad&aacute;). Auparavant, elle a enseigné l'espagnol aux demandeurs d'asile et aux bénéficiaires de la protection internationale à Madrid. Pendant de nombreuses années, il s'est consacré au monde du tourisme et a vécu en Finlande, en République dominicaine, en Haïti, en Espagne et au Canada.
        </p>


        <p>
          Depuis 2016, Manuel enseigne l'espagnol aux étrangers. Il cumule plus de 1900 heures de formation certifiée, dont un Master en Linguistique Appliquée à l'Enseignement de l'Espagnol Langue Étrangère, ainsi que 180 heures de formation spécifique à l'enseignement des langues en ligne et à la conception de matériel. Il est également accrédité par l'Institut Cervantes en tant qu'examinateur DELE.
        </p>

        <p>
          Manuel parle anglais et français. Il aime découvrir différentes cultures, regarder des films ou des documentaires, lire et faire du vélo. De plus, il est passionné d'espagnol.
        </p>

        <p>
          Bienvenue et nous espérons que vous apprécierez l'apprentissage de cette langue!
        </p>
      </div>
    </div>
  )
}