export default function ShopFre () {
  return (
    <div className="shop">
      
      <a href="mailto:info@casanovaspanish.com?subject=Free trial lesson"><button className="shop-button">Leçon d´essai gratuite</button></a>
      <a href="mailto:info@casanovaspanish.com?subject=Online lessons"><button className="shop-button">Cours en ligne</button></a>
      <div className="shop-lessons">
      <a href="mailto:info@casanovaspanish.com?subject=60 minute lessons"><button className="shop-button">60 min - 36.45 CAD</button></a>
      <a href="mailto:info@casanovaspanish.com?subject=90 minute lessons"><button className="shop-button">90 min - 46.80 CAD</button></a>
      </div>

    </div>
  )
}