//CSS
import "./styling/home.css"

//Components
import HomeEng from "./english/HomeEng";
import HomeSpa from "./spanish/HomeSpa";
import HomeFre from "./french/HomeFre";


export default function Home({ language }) {
  return (
    <div>

      {language === 'English' && (
        <HomeEng language={language}/>
      )}

      {language === 'Spanish' && (
        <HomeSpa language={language}/>
      )}

      {language === 'French' && (
        <HomeFre language={language}/>
      )}

    </div>
  )
}