//Image
import { useNavigate } from "react-router-dom"
import manuel2 from "../../images/images-casanova/manuel2.jpg"

//Components
import FullCalendar from "../FullCalendar.jsx"

export default function HomeSpa ({language}) {

const navigate = useNavigate()

  return (
    <>
    <FullCalendar language={language}/>

    <div className="home">
      <img className='home-picture' src={manuel2} alt="Manuel" />
      <div className="home-list">
        <ul>
          <li>&iexcl;Hola! Soy Manuel, profesor de espa&ntilde;ol en Vancouver, B.C.</li>
          <li>Clases en l&iacute;nea.</li>
          <li>+1900 horas de formaci&oacute;n 
          <button id="li-a-black" onClick={() => navigate('/certificates')} rel="noreferrer" target="_blank"> certificada</button>.</li>
          <li>+3500 horas de clase (niveles A1-C2 del <a id="text-link" href="https://es.wikipedia.org/wiki/Marco_Com%C3%BAn_Europeo_de_Referencia_para_las_lenguas" rel="noreferrer" target="_blank">CEFR</a> y clases de alfabetizaci&oacute;n).</li>
          <li><a id="text-link" href="https://badges.wes.org/Evidence?i=b093bdce-4fa3-420d-8abc-c67c4782d8ea&type=ca" rel="noreferrer" target="_blank">M&aacute;ster</a> en Ling&uuml;&iacute;stica Aplicada a la Ense&ntilde;anza del Espa&ntilde;ol como Lengua Extranjera.</li>
          <li>Cursos de formaci&oacute;n de profesores de ELE del <a id="text-link" href="https://www.cervantes.es/sobre_instituto_cervantes/informacion.htm" rel="noreferrer" target="_blank">Instituto Cervantes </a> 
          (+300 horas).</li>
          <li>Formaci&oacute;n espec&iacute;fica en ense&ntilde;anza de idiomas en l&iacute;nea y dise&ntilde;o de materiales (180 horas).</li>
          <li>Acreditado por el Instituto Cervantes como examinador <a id="text-link" href="https://examenes.cervantes.es/es/dele/que-es" rel="noreferrer" target="_blank">DELE</a>.</li>
        </ul>
      </div>
    </div>
    </>
  )
}