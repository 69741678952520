//CSS
import "./styling/about.css"


//Components
import AboutEng from "./english/AboutEng";
import AboutSpa from "./spanish/AboutSpa";
import AboutFre from "./french/AboutFre";


export default function About({ language }) {
  return (
    <div className="about">

      {language === 'English' && (
        <AboutEng />
      )}

      {language === 'Spanish' && (
        <AboutSpa />
      )}

      {language === 'French' && (
        <AboutFre />
      )}

    </div>
  )
}