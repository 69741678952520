//CSS
import "./styling/navbar.css"

//Components
import NavbarSpa from "./spanish/NavbarSpa";
import NavbarFre from "./french/NavbarFre";
import NavbarEng from "./english/NavbarEng";


export default function Navbar({ language, changeToEnglish, changeToFrench, changeToSpanish }) {

  // function openNav() {
  //   document.getElementById("myNav").style.width = "100%";
  // }

  // function closeNav() {
  //   document.getElementById("myNav").style.width = "0%";
  // }

  return (


    <div className="navbars">

      {language === 'English' && (
        <NavbarEng changeToFrench={changeToFrench} changeToSpanish={changeToSpanish}/>
      )}

      {language === 'Spanish' && (
        <NavbarSpa changeToEnglish={changeToEnglish} changeToFrench={changeToFrench}/>
      )}

      {language === 'French' && (
        <NavbarFre changeToSpanish={changeToSpanish} changeToEnglish={changeToEnglish}/>
      )}

    </div>



  )
}

