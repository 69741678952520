
//CSS
import "./styling/certificates.css"

//Images
import nebrija from '../images/diplomas/diploma-nebrija.png';
import auditiva from '../images/diplomas/diploma-ic-auditiva.jpg';
import deleA from '../images/diplomas/diploma-ic-deleA.jpg';
import deleA2B1 from '../images/diplomas/diploma-ic-deleA2B1.png';
import deleB from '../images/diplomas/diploma-ic-deleB.png';
import deleC from '../images/diplomas/diploma-ic-deleC.png';
import edicionDigital from '../images/diplomas/diploma-ic-edicion-digital.jpg';
import eleEnLinea from '../images/diplomas/diploma-ic-ele-en-linea.jpg';
import formacionInicial from '../images/diplomas/diploma-ic-formacion-inicial.jpg';
import gramaticaA from '../images/diplomas/diploma-ic-gramaticaA.jpg';
import gramaticaB from '../images/diplomas/diploma-ic-gramaticaB.jpg';
import hemingwayAvanzado from '../images/diplomas/diploma-ic-hemingway-avanzado.jpg';
import hemingway from '../images/diplomas/diploma-ic-hemingway.jpg';
import sistemaVerbal from '../images/diplomas/diploma-ic-sistema-verbal.png';


const certificates = [

  {
    id: 1,
    name_english: "Master in Applied Linguistics / Teaching of Spanish as a Foreign Language",
    name_spanish: "Máster en Lingüística Aplicada a la Enseñanza del Español como Lengua Extranjera",
    name_french: "Master en Linguistique Appliquée / Enseignement de l'Espagnol comme Langue Étrangère",
    picture: nebrija,
    link: `https://badges.wes.org/Evidence?i=b093bdce-4fa3-420d-8abc-c67c4782d8ea&type=ca`,
    highlight: true,
    display: "horizontal"

  },

  {
    id: 2,
    name_english: "Introductory TSSOL training course",
    name_spanish: "Formación inicial para profesores de ELE",
    name_french: "Formation initiale des professeurs d'espagnol",
    picture: formacionInicial,
    link: "https://cfp.cervantes.es/actividades_formativas/programas_anteriores/cursos_2016.htm",
    highlight: true,
    display: "vertical"
  }
  ,
  {
    id: 3,
    name_english: "Verbal system teaching",
    name_spanish: "Enseñanza del sistema verbal",
    name_french: "Enseignement du système verbal",
    picture: sistemaVerbal,
    link: "https://cfp.cervantes.es/actividades_formativas/cursos/cursos_cervantes/CFP508-19.htm",
    highlight: false,
    display: "vertical"
  },

  {
    id: 4,
    name_english: "Accreditation of DELE examiners: CEFR C1 and C2 levels",
    name_spanish: "Acreditación de examinadores DELE: niveles MCER C1 y C2",
    name_french: "Accréditation des examinateurs DELE : niveaux CECR C1 et C2",
    picture: deleC,
    link: "https://cfp.cervantes.es/actividades_formativas/cursos/cursos_cervantes/CFP450-17.htm",
    highlight: false,
    display: "vertical"
  },

  {
    id: 5,
    name_english: "Accreditation of DELE examiners: CEFR B1 and B2 levels",
    name_spanish: "Acreditación de examinadores DELE: niveles MCER B1 y B2",
    name_french: "Accréditation des examinateurs DELE : niveaux CECR B1 et B2",
    picture: deleB,
    link: "https://cfp.cervantes.es/actividades_formativas/cursos/cursos_cervantes/CFP447-17.htm"
  }
  ,
  {
    id: 6,
    name_english: "Accreditation of DELE examiners: CEFR A2 and B1 for young learners",
    name_spanish: "Acreditación de examinadores DELE: MCER A2 y B1 para escolares",
    name_french: "Accréditation des examinateurs DELE : CECR A2 et B1 pour jeunes apprenants",
    picture: deleA2B1,
    link: "https://cfp.cervantes.es/actividades_formativas/cursos/cursos_cervantes/CFP448-17.htm",
    highlight: false,
    display: "vertical"
  },

  {
    id: 7,
    name_english: "Accreditation of DELE examiners: CEFR A1 and A2",
    name_spanish: "Acreditación de examinadores DELE: MCER A1 y A2",
    name_french: "Accréditation des examinateurs DELE : CECR A1 et A2",
    picture: deleA,
    link: "https://cfp.cervantes.es/actividades_formativas/cursos/cursos_cervantes/CFP447-17.htm",
    highlight: false,
    display: "vertical"
  },

  {
    id: 8,
    name_english: "CEFR A-level grammar analysis",
    name_spanish: "Bases para el análisis y el tratamiento de la gramática en el nivel A",
    name_french: "Analyse grammaticale du niveau A du CECR",
    picture: gramaticaA,
    link: "https://cfp.cervantes.es/actividades_formativas/programas_anteriores/cursos_2016.htm",
    highlight: false,
    display: "vertical"
  }
  ,
  {
    id: 9,
    name_english: "CEFR B-level grammar analysis",
    name_spanish: "Bases para el análisis y el tratamiento de la gramática en el nivel B",
    name_french: "Analyse grammaticale du niveau B du CECR",
    picture: gramaticaB,
    link: "https://cfp.cervantes.es/actividades_formativas/programas_anteriores/cursos_2016.htm",
    highlight: false,
    display: "vertical"
  },
  {
    id: 10,
    name_english: "Online Spanish teaching",
    name_spanish: "Enseñanza de ELE en línea",
    name_french: "Enseignement de l'Espagnol en ligne",
    picture: eleEnLinea,
    link: "https://www.nebrija.com/otros-cursos/cursos-formacion-continua-profesores/",
    highlight: false,
    display: "vertical"
  },

  {
    id: 11,
    name_english: "Language teaching materials design and digital editing",
    name_spanish: "Diseño y edición digital de materiales didácticos de idiomas",
    name_french: "Conception de supports d'enseignement des langues et édition numérique",
    picture: edicionDigital,
    link: "https://cfp.cervantes.es/actividades_formativas/programas_anteriores/cursos_2015.htm",
    highlight: false,
    display: "vertical"
  }
  ,
  {
    id: 12,
    name_english: "Listening comprehension teaching",
    name_spanish: "Didáctica de la comprensión auditiva",
    name_french: "Enseignement de la compréhension orale",
    picture: auditiva,
    link: "https://cfp.cervantes.es/actividades_formativas/programas_anteriores/cursos_2015.htm",
    highlight: false,
    display: "vertical"
  },
  {
    id: 13,
    name_english: "Advanced course for teachers of Spanish as a second language",
    name_spanish: "Curso avanzado para profesores de español como segunda lengua",
    name_french: "Cours avancé pour professeurs d'espagnol langue seconde",
    picture: hemingwayAvanzado,
    link: "https://www.institutohemingway.com/es/cursos/20/curso-profesores-ele-avanzado.php",
    highlight: false,
    display: "horizontal"
  },

  {
    id: 14,
    name_english: "Course for teachers of Spanish as a second language",
    name_spanish: "Curso para profesores de español como segunda lengua",
    name_french: "Cours pour professeurs d'espagnol langue seconde",
    picture: hemingway,
    link: "https://www.institutohemingway.com/es/cursos/1/curso-profesores-espanol-ele-online.php",
    highlight: false,
    display: "horizontal"
  }

]


export default function Certificates({ language }) {

  return (
    <div className="certificates-component">
      <div className="certificates-title">
        {language === 'English' && (
          "Certificates"
        )}
        {language === 'Spanish' && (
          "Certificados"
        )}
        {language === 'French' && (
          "Certificats"
        )}
      </div>

      <div className="certificates">


        {certificates.map((certificate) => (

          <div
            className="certificate"
            key={certificate.id}
          >
            <div className="certificate-img" >
              <img className="certificate-img-img"
                src={certificate.picture}
                alt="diploma"
                data-toggle="modal"
                data-target={`#${certificate.picture}`}
              />
            </div>

            {/* Modal */}
            <div className="modal fade" id={certificate.picture} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <img className=
                      {certificate.display === "horizontal" ? "horizontal-diploma" : 'certificate-img-in-modal'}

                      src={certificate.picture}
                      alt="diploma"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className=
              {certificate.highlight === true ? "certificate-name --highlight" : "certificate name"}
            >

              <a id="certificate-name-link" href={certificate.link} rel="noreferrer" target="_blank">

                {language === 'English' && (
                  `${certificate.name_english}`
                )}
                {language === 'Spanish' && (
                  `${certificate.name_spanish}`
                )}
                {language === 'French' && (
                  `${certificate.name_french}`
                )}
              </a>


            </div>
          </div>

        ))}



      </div>

    </div>
  )
}

