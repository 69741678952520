export default function Podcasts () {
  return (
    <div className="resource-component --text-center">
<ul>
  <li id="id-podcast"><a id="text-link-movie" href="https://www.newsinslowspanish.com/" rel="noreferrer" target="_blank">News in slow Spanish</a></li>
  <li id="id-podcast"><a id="text-link-movie" href="https://podcastsele.com/" rel="noreferrer" target="_blank">Podcasts ELE</a></li>
  <li id="id-podcast"><a id="text-link-movie" href="https://open.spotify.com/show/4WYj81C37trSAv42zwnipj?si=S8D6xQTMQ1a9loA6ZRB3eg&nd=1" rel="noreferrer" target="_blank">Coffe break Spanish</a></li>
  <li id="id-podcast"><a id="text-link-movie" href="https://open.spotify.com/show/1xqkbLwjrxs4kRAEhCKTOs?si=SOgkmX5nSnOSkJMKL9bnMw&nd=1" rel="noreferrer" target="_blank">Spanish answers</a></li>
  <li id="id-podcast"><a id="text-link-movie" href="https://open.spotify.com/playlist/46tDD9b7qtxbfWQ6h4Cjci?si=uLOEFw44TQqzdCus9LGzNA&nd=1" rel="noreferrer" target="_blank">Learn Spanish in your car</a></li>
  <li id="id-podcast"><a id="text-link-movie" href="https://www.stitcher.com/show/latinele-podcast" rel="noreferrer" target="_blank">Latin ELE</a></li>
  <li id="id-podcast"><a id="text-link-movie" href="https://open.spotify.com/show/0lbgO5cBd3tzXKF8cd2DnO?si=MTGp4nnRTWO3N8tw_inUCQ&nd=1" rel="noreferrer" target="_blank">Español automático</a></li>
  <li id="id-podcast"><a id="text-link-movie" href="https://open.spotify.com/show/0HIkKAHEpxviCC0Z40aW2R?si=OOEPguFxRNKJLRuboCAJxQ&nd=1" rel="noreferrer" target="_blank">Handy Spanish</a></li>
  <li id="id-podcast"><a id="text-link-movie" href="https://cadenaser.com/podcast/" rel="noreferrer" target="_blank">Cadena SER</a></li>
</ul>
  </div>
  )
}