//Image
import { useNavigate } from "react-router-dom"
import manuel2 from "../../images/images-casanova/manuel2.jpg"

//Components
import FullCalendar from "../FullCalendar.jsx"

export default function HomeFre ({language}) {

const navigate = useNavigate()

  return (
    <>
    <FullCalendar language={language}/>

    <div className="home">
      <img className='home-picture' src={manuel2} alt="Manuel" />
      <div className="home-list">
        <ul>
          <li>Salut! Je suis Manuel, professeur d'espagnol langue &eacute;trang&egrave;re &agrave; Vancouver, B.C.</li>
          <li>Classes en ligne.</li>
          <li>+1900 heures de formation 
            
            <button id="li-a-black" onClick={() => navigate('/certificates')} rel="noreferrer" target="_blank"> certifi&eacute;e. </button>
            </li>
          <li>+3500 heures de classe (niveaux A1-C2 du <a id="text-link" href="https://www.coe.int/fr/web/common-european-framework-reference-languages/?" rel="noreferrer" target="_blank">CECR</a> et classes d'alphab&eacute;tisation).</li>
          <li><a id="text-link" href="https://badges.wes.org/Evidence?i=b093bdce-4fa3-420d-8abc-c67c4782d8ea&type=ca" rel="noreferrer" target="_blank">Master</a> en linguistique appliqu&eacute;e &agrave; l'enseignement de l'espagnol comme langue &eacute;trang&egrave;re.</li>
          <li>Cours de formation pour les professeurs ELE &agrave; l' <a id="text-link" href="https://fr.wikipedia.org/wiki/Institut_Cervantes" rel="noreferrer" target="_blank">Institut Cervantes </a> 
          (+300 heures).</li>
          <li>Formation &agrave; l'enseignement des langues en ligne et &agrave; la conception des mat&eacute;riaux (180 heures).</li>
          <li>Accr&eacute;dit&eacute; en tant qu'examinateur <a id="text-link" href="https://fr.wikipedia.org/wiki/Diplomas_de_Espa%C3%B1ol_como_Lengua_Extranjera" rel="noreferrer" target="_blank">DELE</a> par l'Institut Cervantes.</li>
        </ul>
      </div>
    </div>
    </>
  )
}