//CSS
import "./resource-styling.css"

//Images
import ser from '../../images/logos-other/ser.png'
import cope from '../../images/logos-other/cope.jpg'
import ondacero from '../../images/logos-other/ondacero.png'
import dial from '../../images/logos-other/dial.png'
import la889 from '../../images/logos-other/889.jpg'
import wradio from '../../images/logos-other/wradio.png'
import lacien from '../../images/logos-other/lacien.png'
import mitre from '../../images/logos-other/mitre.png'

export default function Radio({ language }) {


  return (


    <div className="resource-component">
      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "Spain"
        )}
        {language === 'Spanish' && (
          "España"
        )}
        {language === 'French' && (
          "Espagne"
        )}
      </div>

      <div className="resource-images">
        <div className="resource-image">
          <a href="https://www.cadenaser.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={ser} alt="ser" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.cope.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={cope} alt="ser" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.cadenadial.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={dial} alt="dial" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.ondacero.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={ondacero} alt="ondacero" /></a>
        </div>
      </div>

      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "Mexico"
        )}
        {language === 'Spanish' && (
          "México"
        )}
        {language === 'French' && (
          "Mexique"
        )}
      </div>

      <div className="resource-images">
        <div className="resource-image">
          <a href="https://889noticias.mx/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={la889} alt="la889" /></a>
        </div>
        <div className="resource-image">
          <a href="https://www.wradio.com.mx/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={wradio} alt="wradio" /></a>
        </div>
      </div>

      <div className="resources-resource-title --text-center">
        {language === 'English' && (
          "Argentina"
        )}
        {language === 'Spanish' && (
          "Argentina"
        )}
        {language === 'French' && (
          "Argentine"
        )}
      </div>

      <div className="resource-images">
        <div className="resource-image">
          <a href="https://la100.cienradios.com/player/la100/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={lacien} alt="lacien" /></a>
        </div>
        <div className="resource-image">
          <a href="https://radiomitre.cienradios.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={mitre} alt="mitre" /></a>
        </div>
      </div>

    </div>



  )
}