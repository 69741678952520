//CSS
import "./styling/resources.css"

//Hooks
import { useNavigate } from "react-router-dom";

//Images
import book from './../images/icons/icon-book.png'
import movie from './../images/icons/icon-movie.png'
import tvseries from './../images/icons/icon-series.png'
import television from './../images/icons/icon-television.png'
import newspaper from './../images/icons/icon-newspaper.png'
import radio from './../images/icons/icon-radio.png'
import podcast from './../images/icons/icon-podcasts.png'
import other from './../images/icons/icon-www.png'

export default function Resources({ language }) {

  const navigate = useNavigate();

  return (
    <div className="resources">

      <button className="resource-button" onClick={() => navigate("/resources/books")}>
        <img className='resources-img' src={book} alt="book" />
        <div className="resource-title">
          {language === 'English' && (
            "Books"
          )}
          {language === 'Spanish' && (
            "Libros"
          )}
          {language === 'French' && (
            "Livres"
          )}
        </div>
      </button>

      <button className="resource-button"
        onClick={() => navigate("/resources/movies")}>
        <img className='resources-img' src={movie} alt="movie" />
        <div className="resource-title">
          {language === 'English' && (
            "Movies"
          )}
          {language === 'Spanish' && (
            "Películas"
          )}
          {language === 'French' && (
            "Films"
          )}
        </div>
      </button>

      <button className="resource-button"
        onClick={() => navigate("/resources/tvseries")}>
        <img className='resources-img' src={tvseries} alt="tvseries" />
        <div className="resource-title">
          {language === 'English' && (
            "TV Series"
          )}
          {language === 'Spanish' && (
            "Series"
          )}
          {language === 'French' && (
            "Séries"
          )}
        </div>
      </button>

      <button className="resource-button"
        onClick={() => navigate("/resources/television")}>
        <img className='resources-img' src={television} alt="television" />
        <div className="resource-title">
          {language === 'English' && (
            "Television"
          )}
          {language === 'Spanish' && (
            "Televisión"
          )}
          {language === 'French' && (
            "Télévision"
          )}
        </div>
      </button>

      <button className="resource-button"
        onClick={() => navigate("/resources/newspapers")}>
        <img className='resources-img' src={newspaper} alt="newspaper" />
        <div className="resource-title">
          {language === 'English' && (
            "Newspapers"
          )}
          {language === 'Spanish' && (
            "Periódicos"
          )}
          {language === 'French' && (
            "Journaux"
          )}
        </div>
      </button>

      <button className="resource-button"
        onClick={() => navigate("/resources/radio")}>
        <img className='resources-img' src={radio} alt="radio" />
        <div className="resource-title">
          Radio
        </div>
      </button>

      <button className="resource-button"
        onClick={() => navigate("/resources/podcasts")}>
        <img className='resources-img' src={podcast} alt="podcast" />
        <div className="resource-title">
          Podcasts
        </div>
      </button>

      <button className="resource-button"
        onClick={() => navigate("/resources/other")}>
        <img className='resources-img' src={other} alt="other" />
        <div className="resource-title">
          {language === 'English' && (
            "Other"
          )}
          {language === 'Spanish' && (
            "Otros"
          )}
          {language === 'French' && (
            "Autre"
          )}
        </div>
      </button>

    </div>
  )
}

// export default function Resources({ language }) {
//   return (
//     <div>

//       {language === 'English' && (
//         <ResourcesEng />
//       )}

//       {language === 'Spanish' && (
//         <ResourcesSpa />
//       )}

//       {language === 'French' && (
//         <ResourcesFre />
//       )}

//     </div>
//   )
// }