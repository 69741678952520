//Image
import { useNavigate } from "react-router-dom"
import manuel2 from "../../images/images-casanova/manuel2.jpg"

//Components
import FullCalendar from "../FullCalendar.jsx"

export default function HomeEng ({language}) {

  const navigate = useNavigate()

  return (
    <>
    <FullCalendar language={language}/>

    <div className="home">
      <img className='home-picture' src={manuel2} alt="Manuel" />
      <div className="home-list">
        <ul>
          <li>Hi! I'm Manuel. A Spanish teacher based in Vancouver, B.C.</li>
          <li>Online lessons.</li>
          <li>+1900 hours of 
          <button id="li-a-black" onClick={() => navigate('/certificates')} rel="noreferrer" target="_blank"> certified </button>
             training.</li>
          <li>+3500 hours of Spanish lessons (levels A1 - C2 of the <a id="text-link" href="https://www.coe.int/en/web/common-european-framework-reference-languages/?" rel="noreferrer" target="_blank">CEFR</a> and literacy lessons).</li>
          <li><a id="text-link" href="https://badges.wes.org/Evidence?i=b093bdce-4fa3-420d-8abc-c67c4782d8ea&type=ca" rel="noreferrer" target="_blank">Master's Degree</a> in Applied Linguistics / Teaching of Spanish as a foreign language.</li>
          <li>TSSOL training courses by the <a id="text-link" href="https://en.wikipedia.org/wiki/Instituto_Cervantes" rel="noreferrer" target="_blank">Instituto Cervantes</a> 
          (+300 hours).</li>
          <li>Specific training in online teaching and editing of learning materials (180 hours).</li>
          <li>Accredited by the Instituto Cervantes as a <a id="text-link" href="https://en.wikipedia.org/wiki/DELE" rel="noreferrer" target="_blank">DELE</a> examiner.</li>
        </ul>
      </div>
    </div>
    </>
    
  )
}