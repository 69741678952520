//CSS
import "./resource-styling.css"

//Images
import cvc from '../../images/logos-other/cvc.png'
import basicgrammar from '../../images/logos-other/basicgrammar.png'
import gramaticasmele from '../../images/logos-other/gramaticasmele.jpg'
import cuadernosgramatica from '../../images/logos-other/cuadernosgramatica.jpg'

import difusion from '../../images/logos-other/difusion.png'
import espanolsantillana from '../../images/logos-other/espanolsantillana.png'
import anayaele from '../../images/logos-other/anayaele.png'
import smele from '../../images/logos-other/smele.png'
import enclaveele from '../../images/logos-other/enclaveele.jpg'
import edelsa from '../../images/logos-other/edelsa.png'
import sgel from '../../images/logos-other/sgel.png'
import edinumen from '../../images/logos-other/edinumen.jpg'


export default function Books({language}) {


  return (
    <div className="resource-component">
      <div className="resources-resource-title">
      {language === 'English' && (
            "Graded reading"
          )}
          {language === 'Spanish' && (
            "Lecturas graduadas"
          )}
          {language === 'French' && (
            "Lectures graduées"
          )}
        </div>
      <div className="resource-paragraph">
      {language === 'English' && (
            "The term graded reading - also known as simplified reading - refers to texts written especially for children learning their first language or for second language learners. The linguistic difficulty and the content of these texts are adapted to different levels of competence of the learners. A graded reading uses a limited vocabulary and a restricted set of grammatical structures, and is usually accompanied by lexical notes in the margin, as well as a glossary and a didactic exploitation."
          )}
          {language === 'Spanish' && (
            "El término lectura graduada, también conocido como lectura simplificada, se refiere a textos escritos especialmente para niños que aprenden su primer idioma o para estudiantes de un segundo idioma. La dificultad lingüística y el contenido de estos textos se adaptan a los diferentes niveles de competencia de los estudiantes. La lectura graduada utiliza un vocabulario limitado y un conjunto restringido de estructuras gramaticales, y suele ir acompañada de notas léxicas al margen, así como de un glosario y una explotación didáctica."
          )}
          {language === 'French' && (
            "Le terme lecture graduée - également appelée lecture simplifiée - fait référence à des textes écrits spécialement pour les enfants apprenant leur langue première ou pour les apprenants d'une langue seconde. La difficulté linguistique et le contenu de ces textes sont adaptés aux différents niveaux de compétence des apprenants. La lecture graduée utilise un vocabulaire limité et un ensemble restreint de structures grammaticales, et est généralement accompagnée de notes lexicales en marge, ainsi que d'un glossaire et d'une exploitation didactique."
          )}
        </div>
      <div className="resource-images">
        <div className="resource-image">

          <a href="https://cvc.cervantes.es/aula/lecturas/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={cvc} alt="Centro Virtual Cervantes" /></a>

        </div>
      </div>


      <div className="resources-resource-title">
      {language === 'English' && (
            "Grammars"
          )}
          {language === 'Spanish' && (
            "Gramáticas"
          )}
          {language === 'French' && (
            "Grammaires"
          )}
        </div>
      <div className="resource-paragraph">
      {language === 'English' && (
            "Self-study grammar books with explanations and practical exercises."
          )}
          {language === 'Spanish' && (
            "Libros de gramática de autoaprendizaje con explicaciones y ejercicios prácticos."
          )}
          {language === 'French' && (
            "Livres de grammaire autodidacte avec explications et exercices pratiques."
          )}
        
        </div>
      <div className="resource-images">
        <div className="resource-image">
          <a href="https://difusion.com/tienda/gramatica-basica-del-estudiante-de-espanol" rel="noreferrer" target="_blank"><img className='resource-image-img' src={basicgrammar} alt="Student's basic grammar" /></a>
        </div>

        <div className="resource-image">
          <a href="https://www.grupo-sm.com/es/sites/sm-espana/files/resources/imagenes/MKT/EN-LA-ESCUELA/ELE/ELE_SM_Gramaticas.pdf" rel="noreferrer" target="_blank"><img className='resource-image-img' src={gramaticasmele} alt="Gram&aacute;ticas de uso" /></a>
        </div>

        <div className="resource-image">
          <a href="https://difusion.com/tienda/cuadernos-de-gramatica-espanola" rel="noreferrer" target="_blank"><img className='resource-image-img' src={cuadernosgramatica} alt="Cuadernos de gram&aacute;tica" /></a>
        </div>
      </div>

      <div className="resources-resource-title">
      {language === 'English' && (
            "Editorials"
          )}
          {language === 'Spanish' && (
            "Editoriales"
          )}
          {language === 'French' && (
            "Éditoriaux"
          )}
        </div>
      <div className="resource-paragraph">
      {language === 'English' && (
            "Editorials that publish materials in Spanish as a Foreign Language (ELE)."
          )}
          {language === 'Spanish' && (
            "Editoriales que publican materiales en español como lengua extranjera (ELE)."
          )}
          {language === 'French' && (
            "Éditoriaux qui publient des documents en espagnol comme langue étrangère (ELE)."
          )}
        </div>
      <div className="resource-images">

        <div className="resource-image">
          <div className="resource-image">
            <a href="https://difusion.com/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={difusion} alt="difusion" /></a>
          </div>
        </div>

        <div className="resource-image">
          <div className="resource-image">
            <a href="https://santillana.com/es/aprendizaje-de-idiomas-/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={espanolsantillana} alt="espanolsantillana" /></a>
          </div>
        </div>

        <div className="resource-image">
          <div className="resource-image">
            <a href="https://www.anayaele.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={anayaele} alt="anayaele" /></a>
          </div>
        </div>

        <div className="resource-image">
          <div className="resource-image">
            <a href="https://www.grupo-sm.com/es/content/ele" rel="noreferrer" target="_blank"><img className='resource-image-img' src={smele} alt="smele" /></a>
          </div>
        </div>

        <div className="resource-image">
          <div className="resource-image">
            <a href="https://enclave-ele.net/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={enclaveele} alt="enclaveele" /></a>
          </div>
        </div>

        <div className="resource-image">
          <div className="resource-image">
            <a href="https://edelsa.es/es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={edelsa} alt="edelsa" /></a>
          </div>
        </div>

        <div className="resource-image">
          <div className="resource-image">
            <a href="https://ele.sgel.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={sgel} alt="sgel" /></a>
          </div>
        </div>

        <div className="resource-image">
          <div className="resource-image">
            <a href="https://edinumen.es/" rel="noreferrer" target="_blank"><img className='resource-image-img' src={edinumen} alt="edinumen" /></a>
          </div>
        </div>

      </div>

    </div>
  )
}